<template>
  <v-app>
    <add-card-header
      :boss40AppUrl="boss40AppUrl"
      :title="title"
      :disabledFinish="disabledFinish"
      class="add-card-header"
      @save="saveCard"
      @cancel="cancel"
    ></add-card-header>

    <v-layout class="action-bars" no-gutters>
      <v-flex></v-flex>
      <v-flex xs6 class="whitebg">
          <v-select
            label="Card Type"
            :items="cardTypes"
            v-model="cardType"
            autocomplete
            v-show="showCardTypes"
          ></v-select>
      </v-flex>
      <v-flex></v-flex>
    </v-layout>
    <v-container
      class="content"
      fluid
      pa-0
      fill-height
      v-if="isAddCreditCard == false"
    >
      <v-layout row fill-height class="justify-center text-xs-center">
        <v-spacer></v-spacer>
        <v-flex xs6>
          <!-- <v-card flat width="1024"> -->
          <v-card flat>
            <v-card-text style="height:100%">
              <v-form
                v-model="valid"
                ref="savedCardForm"
                lazy-validation
                style="height:100%"
              >
                <v-layout row text-xs-left title>
                  <v-flex xs12>
                    Credit Card
                  </v-flex>
                </v-layout>
                <v-layout row fill-height align-center>
                  <v-flex class="subheading shrink fill-height">
                    <v-icon>fa-credit-card</v-icon> <strong>Saved Card:</strong>
                    {{ `${account.name} (****${lastFourDigits})` }}
                  </v-flex>
                  <v-flex class="text-xs-right">
                    <v-btn color="primary" @click="openAddCreditCard" outline>
                      Change Credit Card
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-form></v-card-text
            ></v-card
          ></v-flex
        >
        <v-spacer></v-spacer></v-layout
    ></v-container>
    <v-container class="content" fluid pa-0 fill-height v-show="isAddCreditCard && cardType == 'VisaMC'">
      <v-layout row fill-height class="justify-center text-xs-center">
        <v-spacer></v-spacer>
        <v-flex xs6>
          <!-- <v-card flat width="1024"> -->
          <v-card flat>
            <v-card-text style="height:100%">
              <v-form
                v-model="valid"
                @submit="saveCard"
                ref="saveCardForm"
                lazy-validation
                style="height:100%"
              >
                <v-layout row text-xs-left title>
                  <v-flex xs12>
                    Credit Card
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-text-field
                    name="ccNumber"
                    prepend-inner-icon="fa-credit-card"
                    v-model="ccNumber"
                    mask="credit-card"
                    placeholder="1234 - 5678 - 90123 - 4567"
                    :rules="cardNumberRules"
                    ><template slot="label">
                      Credit Card Number<red-asterisk></red-asterisk></template
                  ></v-text-field>
                </v-layout>
                <v-layout row>
                  <v-flex xs3>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <v-text-field
                        style="max-height: 44px"
                        slot="activator"
                        v-model="ccExpiry"
                        prepend-inner-icon="fa-calendar-alt"
                        placeholder="YYYY-MM"
                        readonly
                        required
                        validate-on-blur
                        :rules="expiryRules"
                        ><template slot="label">
                          Expiry Date<red-asterisk></red-asterisk></template
                      ></v-text-field>
                      <v-date-picker
                        v-model="ccExpiry"
                        type="month"
                        @input="menu = false"
                        :month-format="getMonths"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs6> </v-flex>
                  <v-flex xs3 class="text-xs-right">
                    <v-text-field
                      name="ccCVD"
                      maxlength="4"
                      type="number"
                      placeholder="●●●●"
                      prepend-inner-icon="fa-lock"
                      :rules="cvdRules"
                      ><template slot="label">
                        Verification Digits<red-asterisk></red-asterisk
                      ></template>
                    </v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row text-xs-left title mt-2>
                  <v-flex shrink mr-3>
                    Billing Information
                  </v-flex>
                  <v-flex grow ml-3>
                    <v-checkbox
                      label="Same as Account Information"
                      v-model="isSameAccountInfo"
                      @change="setBillingInformation"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs4>
                    <v-text-field
                      name="ccName"
                      v-model="ccName"
                      placeholder="e.g. Akihiro Akiyama"
                      :rules="nameRequiredRule"
                      :disabled="isSameAccountInfo"
                      ><template slot="label">
                        Name on Card<red-asterisk></red-asterisk></template
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs2 mr-3>
                    <v-text-field
                      name="ccStreetNumber"
                      v-model="ccStreetNumber"
                      placeholder="e.g. 30"
                      maxlength="10"
                      :rules="streetNumRequiredRule"
                      :disabled="isSameAccountInfo"
                      ><template slot="label">
                        Street Number<red-asterisk></red-asterisk></template
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs7 mx-3>
                    <v-text-field
                      name="ccStreetName"
                      v-model="ccStreetName"
                      placeholder="e.g. Durham Street South"
                      maxlength="20"
                      :rules="streetRequiredRule"
                      :disabled="isSameAccountInfo"
                      ><template slot="label">
                        Street Name<red-asterisk></red-asterisk></template
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs3 ml-3>
                    <v-text-field
                      name="ccZipPostalCode"
                      v-model="ccZipPostalCode"
                      placeholder="e.g. M9V3L3 or 12345"
                      maxlength="10"
                      required
                      :rules="postalRequiredRule"
                      :disabled="isSameAccountInfo"
                      ><template slot="label">
                        Zip/Postal Code<red-asterisk></red-asterisk></template
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs6 mr-3>
                  <v-text-field
                    name="ccEmailAddress"
                    v-model="ccEmailAddress"
                    placeholder="e.g. aa@acmeinstallers.com"
                    maxlength="128"
                    :rules="emailRules"
                    required
                    :disabled="isSameAccountInfo"
                    ><template slot="label">
                      Email Address<red-asterisk></red-asterisk></template
                  ></v-text-field>
                  </v-flex>
                  <v-flex xs6 ml-3>
                  <v-text-field
                    name="ccPhoneNumber"
                    v-model="ccPhoneNumber"
                    placeholder="e.g. (705)-777-7777"
                    maxlength="10"
                    :rules="phoneNumberRequiredRule"
                    :mask="phoneNumberMask"
                    required
                    :disabled="isSameAccountInfo"
                    ><template slot="label">
                      Phone Number<red-asterisk></red-asterisk></template
                  ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
    </v-container>
    <v-container class="content" fluid pa-0 fill-height v-show="isAddCreditCard && cardType == 'AMEX'">
      <v-layout row fill-height class="justify-center text-xs-center">
        <v-flex xs3></v-flex>
        <v-flex xs6 pt-2 class="stripe-form">
          <stripe-element-card
            v-if="publishableKey"
            ref="elementRef"
            :pk="publishableKey"
            @token="tokenCreated"
            :hidePostalCode=true
          />
        </v-flex>
        <v-flex xs3></v-flex>
      </v-layout>
    </v-container>
    <add-card-footer :company="company"></add-card-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import AddCardFooter from "../components/AddCard/AddCardFooter.vue";
import AddCardHeader from "../components/AddCard/AddCardHeader.vue";
import RedAsterisk from "@/components/RedAsterisk.vue";
import { StripeElementCard } from '@vue-stripe/vue-stripe';

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    AddCardFooter,
    AddCardHeader,
    RedAsterisk,
    StripeElementCard
  },
  data: () => ({
    valid: false,
    menu: false,
    cardType: "VisaMC",
    cardTypes: [
      {
        text: "VISA/MasterCard",
        value: "VisaMC"
      },
      {
        text: "American Express",
        value: "AMEX"
      }
    ],
    BAN: "",
    clientToken: "",
    myAccountToken: "",
    process: "",
    boss40AppUrl: "",
    ccNumber: "",
    ccExpiry: "",
    ccStreetNumber: "",
    ccStreetName: "",
    ccZipPostalCode: "",
    ccEmailAddress: "",
    ccName: "",
    ccPhoneNumber: "",
    account: {
      name: "",
      streetNumber: "",
      street: "",
      mailCode: "",
      email: "",
      phone: "",
    },
    showCardTypes: false,
    isSameAccountInfo: false,
    lastFourDigits: null,
    isAddCreditCard: null,
    company: { name: "Mircom", email: "" },
    phoneNumberMask: "(###) ###-####",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    expiryRules: [
      (v) => !!v || "Expiry is required",
      (v) => /^[0-9\\-]{7}$/.test(v) || "Invalid Year/Month",
    ],
    postalRequiredRule: [(v) => !!v || "Postal/Zip Code is required"],
    streetRequiredRule: [(v) => !!v || "Street Name is required"],
    streetNumRequiredRule: [(v) => !!v || "Street Number is required"],
    nameRequiredRule: [(v) => !!v || "Required"],
    phoneNumberRequiredRule: [(v) => !!v || "Phone number is required"],
    cvdRules: [
      (v) => !!v || "Card Validation Digits is required",
      (v) =>
        /^[0-9]{3,4}$/.test(v) ||
        "Card Validation Digits requires 3 or 4 digits",
    ],
    cardNumberRules: [
      (v) => !!v || "Card Number is required",
      (v) =>
        /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}|4[0-9]{12}(?:[0-9]{3})$/.test(
          v
        ) || "Invalid Card Number",
    ],
    months: [
      "02 - FEB",
      "03 - MAR",
      "04 - APR",
      "05 - MAY",
      "06 - JUN",
      "07 - JUL",
      "08 - AUG",
      "09 - SEP",
      "10 - OCT",
      "11 - NOV",
      "12 - DEC",
      "01 - JAN",
    ],
    token: null,
    publishableKey: null,
    cardNumber: null,
    cardExpiry: null,
    cardCvC: null,
    status: null,
    pubKey: null,
    clientSecret: null,
  }),
  async mounted() {
    this.myAccountToken = this.$route.query.token
    this.deleteQueryString("token")
    await this.decodeMyAccountToken()
    
    if(this.account.country == "US")
      this.showCardTypes = true;

    await axios
      .post(process.env.VUE_APP_PGAPI + "api/config/getboss40urls", {
        clientToken: this.clientToken
      })
      .then((resp) => {
        this.boss40AppUrl = resp.data.Boss40AppUrl;
      });

    await axios
      .post(process.env.VUE_APP_PGAPI + "api/values/getkey", {
        BAN: this.BAN,
        PayGateToken: this.clientToken
      })
      .then((resp) => {
        if (
          resp.data != null &&
          resp.data != "" &&
          typeof resp.data != undefined
        ) {
          this.isAddCreditCard = false;
          this.lastFourDigits = resp.data;
        } else {
          this.isAddCreditCard = true;
        }
      });
      await this.getPubKey()
  },
  beforeDestroy(){
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  computed: {
    disabledFinish() {
      return !this.valid;
    },
    miconnectRedirectApp() {
      switch (this.process) {
        case "passwordReset":
          return "tx3-password-reset";
        case "register":
          return "register";
        case "buildingManagerSignup":
          return "building-manager-signup";
        default:
          return "register";
      }
    },
    hasSavedCard() {
      return this.lastFourDigits ? true : false;
    },
    title() {
      var title = "Add Credit Card Information";
      if (this.hasSavedCard && this.isAddCreditCard == false) {
        title = "Confirm Credit Card Information";
      }
      return title;
    },
    stripeElements() {
      return this.$stripe.elements();
    },
  },
  methods: {
    async saveCard() {
      if(this.cardType == "VisaMC"){
        if (
          this.isAddCreditCard == false &&
          this.$refs.savedCardForm.validate()
        ) {
          location.assign(
            this.boss40AppUrl +
              "#/miconnect/" +
              this.miconnectRedirectApp +
              "?isCardEntered=true&cardName=" +
              this.account.name +
              "&lastDigits=" +
              this.lastFourDigits
          );
        } else if (
          this.isAddCreditCard == true &&
          this.$refs.saveCardForm.validate()
        ) {
          //console.log('Form is valid');
          var BAN = this.BAN;
          var paygatetoken = this.clientToken;

          var ccPerson = {
            email: this.ccEmailAddress,
            phoneNumber: this.ccPhoneNumber,
          };

          var ccDetails = {
            ccNumber: this.ccNumber,
            ccExpiry: this.ccExpiry,
          };

          var ccAddress = {
            streetNumber: this.ccStreetNumber,
            streetName: this.ccStreetName,
            postalZip: this.ccZipPostalCode,
          };

          await axios
            .post(
              process.env.VUE_APP_PGAPI + "api/card/post",
              { ccPerson, ccAddress, ccDetails, BAN, paygatetoken }
            )
            .then((res) => {
              var lastFourDigits = res.data.Result.last4;
              location.assign(
                this.boss40AppUrl +
                  "#/miconnect/" +
                  this.miconnectRedirectApp +
                  "?isCardEntered=true&cardName=" +
                  this.ccName +
                  "&lastDigits=" +
                  lastFourDigits
              );
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      else if(this.cardType == "AMEX"){
        await this.$refs.elementRef.submit()
      }
    },
    cancel() {
      location.replace(
        this.boss40AppUrl + "#/miconnect/" + this.miconnectRedirectApp
      );
    },
    deleteQueryString(queryString) {
      let query = Object.assign({}, this.$route.query);
      delete query[`${queryString}`];
      this.$router.replace({ query });
    },
    setBillingInformation() {
      if (this.isSameAccountInfo) {
        this.ccStreetNumber = this.account.streetNumber;
        this.ccStreetName = this.account.street;
        this.ccZipPostalCode = this.account.mailCode;
        this.ccEmailAddress = this.account.email;
        this.ccPhoneNumber = this.account.phoneNumber;
        this.ccName = this.account.name;
      }
    },
    openAddCreditCard() {
      var confirmAddCreditCard = confirm(
        "WARNING: Are you sure you would like to replace the credit card on file with a new card?"
      );
      if (confirmAddCreditCard) {
        this.isAddCreditCard = true;
      }
    },
    getMonths(month) {
      let i = new Date(month).getMonth(month)
      return this.months[i]
    },
    async decodeMyAccountToken(){
      await axios
        .post(process.env.VUE_APP_PGAPI + `api/boss40/DecodeMyAccountToken/?token=${this.myAccountToken}`)
        .then((resp) => {
          this.clientToken = resp.data.PaygateToken
          this.BAN = resp.data.AccountNumber
          this.process = resp.data.Process
          this.account.phoneNumber = resp.data.PhoneNumber
          this.account.email = resp.data.Email
          this.account.name = `${resp.data.FirstName} ${resp.data.LastName}`
          this.account.streetNumber = resp.data.StreetNumber
          this.account.street = resp.data.StreetName
          this.account.mailCode = resp.data.MailCode
          this.account.country = resp.data.Country
        });
    },
    async getPubKey() {
      let pubKey = await axios.post(process.env.VUE_APP_PGAPI + "api/values/stripepubkey", {PayGateToken: this.clientToken})
      this.publishableKey = pubKey.data
    },
    async tokenCreated (token) {
        this.token = token
        let card = {
            BAN: this.BAN,
            payGateToken: this.clientToken,
            card: this.token.id, 
            ccDetails: {
                ccNumber: this.token.card.last4
            },
            email: this.account.email,
            name: this.account.name,
            isAmex: true
        }
        await axios.post(process.env.VUE_APP_PGAPI + "api/card/post", card)
        .then(res => {
            if (res.status == 200) {
              location.assign(
                this.boss40AppUrl + 
                "#/miconnect/" + 
                this.miconnectRedirectApp + 
                "?isCardEntered=true&cardName=" + 
                this.account.name + 
                "&lastDigits=" + 
                this.token.card.last4
              );
            }
        })
        .catch((error) =>{
            console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.add-card-header {
  min-height: 50px;
}
.page-title {
  font-size: x-large;
  font-weight: bold;
}
.content {
  /* flex-grow: 1;
  overflow-x: auto; */
  background-color: #eae8ea;
}
.whitebg {
  background-color: white;
}
.action-bars {
  background-color: #f0f0f0 !important;
  min-height: 52px;
  max-height: 52px;
}
.card-title{
    font-family: Roboto, sans-serif;
    font-size: 20px;
}
.form-field-label{
    color: grey;
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.form-field{
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    border-bottom-style: solid;
}
.stripe-form {
  background-color: white !important;
  height: 50px !important;
}
/deep/div.v-input.v-input--selection-controls.v-input--checkbox {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
